<template>
    <div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;min-height:670px">
			<div class="title"> 现场进度节点设置 </div>
			<div class="line"></div>
            <div>
            <el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;height: 45px;margin-left:-20px;width: 105%;">
                <el-col :span="pageSpan" style="margin-left:15px;margin-top:5px">
                    <el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
                        @click="addOrUpdateHandle()">添加</el-button>
                    <el-button v-preventReClick :disabled="disabledBatchDelete || updateFlg || addFlg" class="btn"
                        type="primary" size="small" @click="deleteBatch()">删除</el-button>
                </el-col>
            </el-row>
            <div class="line" style="margin-top:-5px"></div>
			<div class="box">
				<el-table height="570" :row-class-name="tableRowClassName" :row-style="selectRowBackground"
					ref="dataListTable" class="dataListTable" :data="dataList" header-align="center" border
					style="width: 100%;margin-bottom: 20px;" header-cell-class-name="dataListTHeader" row-key="sceneId"
					@selection-change="handleSelectionChange" :cell-style="{ padding: '0px' }">
					<el-table-column type="selection" width="60" align="center"></el-table-column>
					<el-table-column type="index" label="NO." width="55" align="center">
					</el-table-column>
					<el-table-column prop="sceneName" label="现场名称" width="300" align="center">
					</el-table-column>
					<el-table-column prop="subNodeName" label="分项节点名称" align="center">
					</el-table-column>
					<el-table-column label="" width="110" align="center">
						<template slot-scope="scope">
							<el-button v-preventReClick type="text" size="small"
								@click="addOrUpdateHandle(scope.row.sceneId)"
								v-if="!updateFlg && !addFlg">修改</el-button>
							<!-- <el-button v-preventReClick type="text" size="small"
								@click="deleteHandle(scope.row.sceneId)"
								v-if="!updateFlg && !addFlg">删除</el-button> -->
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
        </el-card>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
	</div>
</template>
<script>
import $common from "@/utils/common.js"
import addOrUpdate from "./projectscene-setting.vue"
import Sortable from "sortablejs";
export default {
	data() {
		return {
			disabledBatchDelete: true,
			pageSpan: 12,
			addOrUpdateVisible: false,
			updateFlg: false,
			addFlg: false,
			form: {},
			workNo: $common.getItem("workNo"),
			subjectNo: '',
			dataList: [],
			multipleSelection: [],
			sceneIds: [],
			list: [],
			tableSelVal: [],
		};
	},
	components: {
		addOrUpdate
	},
	mounted() {
		this.getDataList();
		this.dragSort();
	},
	methods: {
		dragSort() {
			const el = this.$refs.dataListTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
			this.sortable = Sortable.create(el, {
				onEnd: e => {	//onEnd是结束拖拽时触发，onUpdate是列表内元素顺序更新的时候触发，更多请看文末配置项
					//e.oldIndex为拖动一行原来的位置，e.newIndex为拖动后新的位置
					const targetRow = this.dataList.splice(e.oldIndex, 1)[0];
					this.dataList.splice(e.newIndex, 0, targetRow);
					let recNo = this.dataList[e.newIndex].recNo;//拖动行的id
					// 后端发送请求排序
					this.$http({
						url: this.$store.state.httpUrl + "/business/projectscene/sort",
						method: "POST",
						data: this.$http.adornData({
							records: this.dataList,
							workNo: $common.getItem("workNo"),
						}),
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: '操作成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
									this.getDataList()
								}
							})
						}
					});
				}
			})
		},
		// 获取数据列表
		getDataList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectscene/list/" + $common.getItem("workNo"),
				method: 'get',
				params: this.$http.adornParams({
				})
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body.records;
				}
			})
		},
		deleteHandle(id) {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectscene/delete/" + id,
					method: 'post'
				}).then(({
					data
				}) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				})
			});
		},
		addOrUpdateHandle(sceneId) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(sceneId)
			})
		},
		handleSelectionChange(selection) {
			this.tableSelVal = selection;
			if (selection.length > 0) {
				this.disabledBatchDelete = false;
			} else {
				this.disabledBatchDelete = true;
			}
		},
		deleteBatch() {
			this.$confirm(`确定对选中的数据进行删除操作？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let records = this.tableSelVal.map((item) => item.sceneId);
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectscene/deleteBatch",
					method: 'post',
					data: this.$http.adornData({
						records: records
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				})
			})
				.catch(() => { })
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
		selectRowBackground({ row }) {
			const checkIdList = this.tableSelVal.map((item) => item.sceneId);
			if (checkIdList.includes(row.sceneId)) {
				return {
					backgroundColor: "#fae8cb",
				};
			}
		},
	}
};
</script>
<style scoped>
</style>
